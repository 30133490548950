//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppMain',
  data: function data() {
    return {
      links: [{
        title: '官网',
        key: '1',
        href: 'https://www.crmeb.com',
        blankTarget: true
      }, {
        title: '社区',
        key: '2',
        href: 'https://q.crmeb.net/?categoryId=122&sequence=0',
        blankTarget: true
      }, {
        title: '文档',
        key: '3',
        href: 'https://help.crmeb.net/crmeb_java/1748037',
        blankTarget: true
      }],
      copyright: 'Copyright © 2020 西安众邦网络科技有限公司'
    };
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }
};